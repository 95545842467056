.table-list-view {
  thead {
    tr {
      background: rgba(255, 255, 255, 0.53);
      @apply rounded;
      td {
        @apply font-medium text-sm;
        padding: 14px 8px;
        @screen sm {
          font-size: 1rem;
        }
        &:first-child {
          padding-left: 64px;
        }
      }
    }
  }

  tbody {
    tr {
      @apply bg-white;
      border-radius: 0px 0px 4px 4px;

      td {
        @apply text-sm;
        padding: 12px 8px;
        @screen sm {
          font-size: 1rem;
        }
        &:first-child {
          padding-left: 24px;
        }
      }

      &.configured {
        @apply bg-grey-100;
      }
    }
  }
}

.table-plan-list {
  @apply w-full;
  border-collapse: separate;
  border-spacing: 0;

  thead {
    tr {
      background: rgba(255, 255, 255, 0.53);
      @apply rounded;
      td {
        @apply font-medium;
        padding: 4px 20px;
      }
    }
  }

  tbody {
    tr {
      @apply bg-white border border-border-color;
      border-radius: 10px;

      td {
        @apply p-6;
        border-top: 1px solid #dbe3ea;
        border-bottom: 1px solid #dbe3ea;

        &:first-child {
          border-left: 1px solid #dbe3ea;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        &:last-child {
          border-right: 1px solid #dbe3ea;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }

        p {
          font-size: 16px;
          font-weight: 400;
        }
      }

      &.configured {
        @apply bg-grey-100;
      }

      &.current {
        @apply text-white;
        background: linear-gradient(180deg, #1d5ab5 0%, #146cbf 100%);
        box-shadow: 0px 14px 44px rgba(98, 161, 218, 0.29);
        border: none;

        td {
          border: transparent !important;

          p {
            color: white;
          }
        }
      }
    }
  }
}

.table-funding-insight {
  tbody {
    tr {
      &:hover {
        // @apply shadow-sm;
      }

      &.row-disabled {
        background-color: #fcfdfc;
        td:first-child {
          @apply text-primary;
        }

        &:hover {
          @apply shadow-none;
        }
      }
    }
  }
}

.table-billing-reports {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &-wrapper {
    @apply bg-white;
  }

  thead {
    tr {
      @apply border-b border-border-color;
      th {
        @apply text-secondary text-sm font-bold text-left px-4 py-[0.875rem] bg-white;
        border-right: 0 !important;
        z-index: 0 !important;

        &:first-child {
          max-width: 210px;
          min-width: 210px;
          padding-left: 2.625rem;
        }

        &.sticky-data {
          @apply sticky top-0 left-0;
          z-index: 1;
        }

        &:nth-child(2).sticky-data {
          left: 15rem;
        }
      }
    }
  }

  tbody {
    tr {
      @apply border-b border-border-color;
      td {
        @apply text-primary text-sm px-4 py-[0.642rem];
        border-right: 0 !important;
        z-index: 0 !important;

        &:first-child {
          max-width: 15rem;
          min-width: 15rem;
          padding-left: 2.625rem;
        }

        &.sticky-data {
          @apply sticky top-0 left-0;
          z-index: 1;
        }

        &:nth-child(2).sticky-data {
          left: 15rem;
        }
      }

      &:nth-child(odd) {
        td {
          background-color: #f9fafb;
        }
      }
      &:hover {
        td {
          background: #d3e0ee !important;
        }
      }
    }
  }
}

.table-funding-insights-subscription {
  tbody {
    tr {
      td {
        &:first-child {
          border-top-left-radius: 0.625rem;
          border-bottom-left-radius: 0.625rem;
        }

        &:last-child {
          border-top-right-radius: 0.625rem;
          border-bottom-right-radius: 0.625rem;
        }
      }

      &.plan-active {
        @apply text-white border-transparent;
        background: linear-gradient(180deg, #1d5ab5 0%, #146cbf 100%);
        box-shadow: 0px 14px 44px rgba(98, 161, 218, 0.29);
      }

      &.plan-upcoming {
        @apply text-white border-transparent;
        background: linear-gradient(180deg, #54658c 0%, #6e90af 100%);
        box-shadow: 0px 14px 44px rgba(98, 161, 218, 0.29);
      }
    }
  }
}
