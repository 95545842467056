@tailwind base;
@tailwind components;
@tailwind utilities;
@import './assets/styles/styles.scss';

body {
  @apply text-primary;
  font-size: 16px;
  font-family: 'Karla', sans-serif;
  letter-spacing: -0.2px;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overscroll-behavior: none;
}

code {
  font-family: 'Karla', sans-serif;
}

input,
button,
code,
select {
  font-family: 'Karla', sans-serif;
  &::placeholder {
    font-family: 'Karla', sans-serif;
  }
}

/* width */
// @media screen and (min-width: 1080px) {
//   ::-webkit-scrollbar {
//     width: 0;
//   }
//  }

/* Track */
::-webkit-scrollbar-track {
  // background: transparent;
}
