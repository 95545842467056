ul,
li {
  list-style: none;
}

.mt-14 {
  margin-top: 14px;
}

.mt-15 {
  margin-top: 15px;
}

.text-11 {
  font-size: 11px;
}

.text-11 {
  font-size: 16px;
}

.text-13 {
  font-size: 13px;
}

.text-base-1 {
  font-size: 15px;
}

.text-22 {
  font-size: 22px;
}
.lg-text-22 {
  font-size: 1.125rem;
  @screen lg {
    font-size: 22px;
  }
}

.text-26 {
  font-size: 26px;
}
.lg-text-26 {
  font-size: 1.125rem;
  @screen lg {
    font-size: 26px;
  }
}
.text-32 {
  font-size: 32px;
}
.lg-text-32 {
  @screen lg {
    font-size: 32px;
  }
}
.ml-15 {
  margin-left: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mt-25 {
  margin-top: 25px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-28 {
  margin-left: 28px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.pb-18 {
  padding-bottom: 18px;
}

.pb-30 {
  padding-bottom: 30px;
}

.px-35 {
  padding-left: 35px;
  padding-right: 35px;
}
.lg-px-35 {
  @screen lg {
    padding-left: 35px;
    padding-right: 35px;
  }
}

.pr-30 {
  padding-right: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.py-9 {
  padding-top: 9px;
  padding-bottom: 9px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-18 {
  padding-right: 18px;
}

.mt-148 {
  margin-top: 148px;
}

.p-30 {
  padding: 30px;
}

.pl-15 {
  padding-left: 15px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.lh-xxs {
  line-height: 1;
}

.lh-xs {
  line-height: 1.1;
}

.lh-sm {
  line-height: 1.2;
}

.lh-md {
  line-height: 1.3;
}

.lh-lg {
  line-height: 1.4;
}

.error-msg {
  @apply text-red;
  font-size: 15px;

  &--sm {
    @apply text-sm;
  }

  &--xs {
    @apply text-xs;
  }
}

.global-authentication-error-msg {
  @apply flex items-center border-l-4 border-red;
  background-color: #ebdcdb;
  border-radius: 5px;
  padding: 8px 10px;
}

.resize-none {
  resize: none;
}

.or-text {
  @apply w-full flex items-center justify-center border-b border-primary-brand-light;

  > span {
    @apply relative flex items-center justify-center text-sm text-secondary bg-white border border-primary-brand-light z-10;
    top: 17px;
    width: 38px;
    height: 38px;
    border-radius: 20px;
  }
}

.page-wrapper {
  @apply pt-16 pb-16 overflow-y-auto min-h-screen;
  background: radial-gradient(
        38.69% 38.69% at 73.78% 31.51%,
        rgba(216, 175, 71, 0.108) 0%,
        rgba(0, 0, 0, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        23.3% 32.19% at 43.13% 44.65%,
        rgba(30, 118, 200, 0.138) 0%,
        rgba(0, 0, 0, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(180deg, #fefefe 0%, #dceff1 100%);
  // @media screen and (max-width: 1180px) {
  //   min-width: 1500px;
  //   // white-space: nowrap;
  //   // overflow: auto;
  //  }
  @screen lg {
    padding-bottom: 0;
  }
}

.page-container {
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  @apply mx-auto;
  @screen lg {
    max-width: 90%;
  }
  @screen xl {
    max-width: 80%;
    padding-left: 13px;
    padding-right: 13px;
  }

  &--md {
    @screen xl {
      max-width: 64%;
    }
  }

  &--xs {
    max-width: 100%;
    padding: 0 20px;
    @screen lg {
      padding: 0 40px;
    }
  }

  // @media screen and (max-width: 1380px) {
  //   min-width: 1280px;
  //   white-space: nowrap;
  // }
}

.card {
  @apply rounded;
  background: rgba(255, 255, 255, 0.5);

  &--white {
    @apply bg-white rounded-lg;
  }

  &--white2 {
    @apply rounded-md;
    background: rgba(255, 255, 255, 0.93);
  }

  &__container {
    @apply flex items-start justify-between;
  }

  &__container_new {
    @apply flex items-start;
  }
}

.stripe-element {
  .stripe-custom {
    width: 100%;
    padding: 11px 15px 11px 0;
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 0.9rem 1.6rem;
    background: #fff;
    border: 1px solid #dadada;
    border-radius: 4px;
    transition: border 300ms;

    &.StripeElement--focus {
      @apply border-primary-brand-light;
    }
  }
}

.Toastify__toast {
  &-container--top-right {
    width: initial !important;
    max-width: 500px !important;
  }
}

.Toastify__toast--success {
  .Toastify__progress-bar--success {
    background: #6cbb8c;
  }

  .Toastify__toast-icon {
    svg path {
      fill: #6cbb8c;
    }
  }
}

.Toastify__toast--warning {
  .Toastify__progress-bar--warning {
    background: #e3c371;
  }

  .Toastify__toast-icon {
    svg path {
      fill: #e3c371;
    }
  }
}

.Toastify__toast--error {
  .Toastify__progress-bar--error {
    background: #db584f;
  }

  .Toastify__toast-icon {
    svg path {
      fill: #db584f;
    }
  }
}

.react-datepicker-wrapper {
  width: 180px !important;
  @apply relative ml-auto;
}

.react-datepicker__input-container {
  .react-datepicker-ignore-onclickoutside,
  > input {
    width: 180px !important;
    padding: 4px 45px 4px 14px;
    @apply relative border border-primary-brand-light ml-auto rounded-lg bg-white cursor-pointer;
    @apply w-full text-base font-bold text-primary cursor-pointer;
    outline: none;
    background: #fff url(../../../icons/icon-calendar.svg) no-repeat right 12px center;
    font-size: 0.9rem;
    box-shadow: 0px 4px 4px #d6e1ea;
  }

  .react-datepicker-ignore-onclickoutside,
  > input[value=''] {
    box-shadow: none;
  }
}

.react-datepicker__close-icon {
  display: none !important;
}

.deliverability-chart {
  .react-datepicker-wrapper {
    width: 170px !important;
  }

  .react-datepicker__input-container {
    .react-datepicker-ignore-onclickoutside,
    > input {
      width: 170px !important;
      color: #1d5ab5;
      font-size: 0.9rem;

      &::placeholder {
        @apply text-primary font-medium;
      }
    }
  }
}

.google-connect-list {
  &-wrapper {
    @apply list-decimal;
  }

  @apply text-black font-medium list-decimal pl-2;

  &__regular-text {
    font-weight: 400;
  }

  &:not(:last-child) {
    @apply mb-2;
    @screen lg {
      margin-bottom: 1.25rem;
    }
  }
}

.google-connect-app-password {
  @apply mt-3;
  @screen lg {
    margin-top: 1.5rem;
  }
}

.breakdown-graph {
  &__stripe {
    height: 1.4rem;
    width: 0.85rem;
    @apply bg-border-color;

    &--default {
      @apply bg-border-color;
    }

    &--error {
      @apply bg-red;
    }

    &--success {
      @apply bg-green;
    }

    &--info {
      @apply bg-primary-brand;
    }

    &--label {
      width: 9px;
      height: 15px;
    }

    &-list {
      @apply flex items-center justify-between p-3 border border-border-color;
      border-radius: 10px;

      .avatar-container {
        @apply flex-shrink-0;
      }

      &-wrapper {
        @apply flex items-center flex-wrap;
      }
    }
  }
}

.editor-pack-wrapper {
  @apply flex flex-col-reverse h-full;

  .toolbar-class {
    border: 0;
    padding: 0;
    margin: 0;
    padding: 0 8px;
    margin-top: 16px;
  }

  .editor-class {
    font-family: 'Karla', sans-serif;
    height: calc(100% - 50px) !important;
    @apply px-4;
  }
}

.checklist {
  @apply flex items-center cursor-pointer;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.6);
  border-left: 5px solid transparent;
  box-shadow: none;
  transition: all 0.3s;

  &:hover,
  &.selected {
    @apply text-primary-brand-dark font-bold bg-white;
    border-left: 5px solid #1d5ab5;
    box-shadow: 0px 4px 104px rgba(29, 49, 67, 0.43);

    .checklist__title {
      @apply font-bold text-primary-brand-dark;
    }

    .checklist__count {
      @apply bg-primary-brand-dark;
    }
  }

  &:first-of-type {
    border-radius: 6px 6px 0px 0px;
  }

  &:last-of-type {
    border-radius: 0px 0px 6px 6px;
  }

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &__title {
    @apply font-medium;
    font-size: 1.05rem;
    transition: all 0.3s;
  }

  &__count {
    width: 26px;
    height: 26px;
    @apply flex items-center justify-center text-lg text-white font-bold rounded-full bg-secondary;
    transition: all 0.3s;
  }
}

.alert-msg {
  padding: 8px 20px;
  @apply flex items-center gap-2;
  background-color: #e1efe0;
  border-radius: 5px;

  &--success {
    background-color: #e1efe0;
  }

  &--warning {
    background-color: rgba(238, 225, 191, 0.54);
  }

  &--error {
    background-color: #efe1e0;
  }

  &__content {
    @apply font-medium;
    color: #1e1e1e;
  }
}

@media screen and (max-width: 1180px) {
  .whitespace-md {
    white-space: nowrap;
  }
}

.icon-refresh {
  opacity: 0.3;
}

.icon-refresh:hover {
  opacity: 1;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// '0-5: Poor-poor',
// '6-7: Fair-fair',
// '8-10: Good-good'
.content-analysis-score {
  @apply text-22 font-bold;

  &__poor {
    @apply text-red;
    // color: #db584f;
  }

  &__fair {
    color: #e3c371;
  }

  &__good {
    color: #6cbb8c;
  }
}

.action-steps {
  &-wrapper {
    ol {
      list-style: decimal;

      li {
        list-style: decimal;
      }
    }
  }
}

.text-linear-gradient {
  background: linear-gradient(90.37deg, #238ac2 0.32%, #48c37a 68.47%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.billing-reports {
  height: calc(100vh - 4rem);
  padding-top: 1.125rem;
  overflow: initial;

  &__container {
    padding: 1rem 2rem 2rem;
  }

  &__filter {
    @apply w-[15.625rem] py-5 bg-white;
    border-radius: 20px;
  }
}

.filters-list {
  &__container {
    @apply max-h-0 opacity-0 overflow-hidden;
    transition: max-height 300ms, opacity 300ms;
    /* Combine transitions */
  }

  &__content {
    @apply opacity-0 invisible;
    transition: opacity 300ms 200ms;
    /* Delay opacity transition */
  }

  &__arrow {
    transition: rotate(0deg);
    transition: all 300ms;
  }
}

.filters-checkbox {
  .filters-list {
    &__container {
      @apply max-h-[1000px] opacity-100;
      overflow: initial;
      transition: all 300ms;
    }

    &__content {
      @apply opacity-100 visible;
    }

    &__arrow {
      transform: rotate(180deg);
    }
  }
}

.chips-list {
  @apply flex items-center justify-between text-primary-brand-dark text-[0.813rem] font-medium py-[0.125rem] px-2 my-[0.188rem] mr-[0.375rem] rounded;
  background-color: rgba(75, 157, 234, 0.2);
}

.filter-dropdown {
  &__content {
    .css-13cymwt-control,
    .css-1fdsijx-ValueContainer,
    .css-t3ipsp-control {
      min-height: 32px !important;
    }

    // .css-1n6sfyn-MenuList {
    //   max-height: 181px !important;
    // }
    // &--size {
    //   .css-1n6sfyn-MenuList {
    //     max-height: 112px !important;
    //   }
    // }
    .css-10wo9uf-option,
    .css-d7l1ni-option {
      color: #485d68 !important;
      font-size: 13px !important;
      font-weight: 500 !important;
      padding: 5px 12px !important;
      transition: all 300ms;
      cursor: pointer;

      &:hover {
        color: #1d5ab5 !important;
        background-color: #dce8f3 !important;
      }
    }

    .rc-slider {
      padding: 0;
      margin-bottom: 32px;
      height: initial;
    }

    .rc-slider-rail {
      height: 5px;
      @apply relative w-full bg-border-color;
      border-radius: 2.5px;
    }

    .rc-slider-track {
      top: 0;
      height: 5px !important;
      background-color: #1d5ab5 !important;
      border-radius: 2.5px !important;
    }

    .rc-slider-handle {
      width: 15px;
      height: 15px;
      top: 0;
      border: transparent;
      background-color: #1d5ab5 !important;
      z-index: 0;
    }

    .rc-slider-mark-text {
      color: #485d68;
      font-size: 13px;
    }
  }

  &__arrow {
    transform: rotate(0deg);
    transition: all 300ms;

    &.open {
      transform: rotate(180deg);
    }
  }
}

input[type='number'] {
  appearance: textfield;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.tab {
  &__list {
    &::after {
      content: '';
      @apply w-full h-1 absolute left-0 bottom-0 bg-primary-brand rounded-t opacity-0 invisible transition-all;
    }

    &.active {
      @apply text-primary font-bold;

      &::after {
        @apply opacity-100 visible;
      }
    }
  }
}

//React-select

.css-1p3m7a8-multiValue {
  padding: 1px 4px !important;
  background: rgba(75, 157, 234, 0.2) !important;
  border-radius: 4px !important;

  .css-wsp0cs-MultiValueGeneric {
    @apply text-primary-brand-dark text-xs font-medium p-0 m-0;
  }
}

.css-12a83d4-MultiValueRemove {
  padding: 0 !important;

  > svg {
    @apply text-primary-brand-dark;
    transition: transform 300ms ease-in;
  }

  &:hover {
    background-color: transparent !important;

    > svg {
      @apply text-primary-brand-dark;
      transform: scale(1.1);
    }
  }
}

.css-1xc3v61-indicatorContainer {
  cursor: pointer;
}

.css-b62m3t-container {
  &:focus-visible {
    outline: none;
  }
}

.css-13cymwt-control:hover {
  border-color: #c8d7e6 !important;
}

.css-t3ipsp-control:focus-within {
  border: 1px solid #4b9dea !important;
  outline: none;
  box-shadow: none !important;
}

.css-1jqq78o-placeholder {
  color: rgba(#485d68, 0.7) !important;
  font-size: 14px !important;
  font-weight: 400;
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a29ea4;
    border-radius: 50px;
  }
}

.react-select-container {
  .react-select {
    &__menu-list {
      max-height: 200px !important;
    }

    &__option {
      color: #485d68 !important;
      font-size: 13px !important;
      font-weight: 500 !important;
      padding: 5px 12px !important;
      transition: all 300ms;
      cursor: pointer;

      &:hover {
        color: #1d5ab5 !important;
        background-color: #dce8f3 !important;
      }
    }

    &__multi-value {
      padding: 1px 4px !important;
      background: rgba(75, 157, 234, 0.2) !important;
      border-radius: 4px !important;

      &__label {
        color: #1d5ab5 !important;
        font-size: 12px !important;
        font-weight: 500 !important;
        padding: 0 !important;
        margin: 0 !important;
      }

      &__remove {
        padding: 0 !important;

        > svg {
          color: #1d5ab5 !important;
          transform: scale(1);
          transition: all 300ms;
        }

        &:hover {
          background-color: transparent !important;

          svg {
            color: #1d5ab5 !important;
            transform: scale(1.1);
          }
        }
      }
    }
  }
  &--size {
    .react-select {
      &__menu-list {
        max-height: 110px !important;
      }
    }
  }
}

.letter-space02 {
  letter-spacing: -0.02em;
}
.primaryfont {
  font-family: 'Karla', sans-serif;
}
.secondaryfont {
  font-family: 'Plus Jakarta Sans', sans-serif;
}
.billing-input-field--search {
  &::placeholder {
    font-size: 14px !important;
    @screen xxxl {
      font-size: 16px !important;
    }
  }
}
.xl-minwidth-initial {
  @screen lg {
    min-width: initial !important;
  }
  @media screen and (max-width: 1024px) {
    min-width: 980px !important;
  }
}
.lg-minwidth-initial {
  @screen lg {
    min-width: initial !important;
  }
  @media screen and (max-width: 980px) {
    min-width: 800px !important;
  }
}
.sm-max-h-initial {
  @screen sm {
    max-height: initial;
  }
}
.funding-reports-mobile {
  height: calc(100vh - 8rem);
  padding: 1.125rem 0 0;
  background: radial-gradient(
      38.69% 38.69% at 73.78% 50.51%,
      rgba(216, 175, 71, 0.108) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    radial-gradient(
      23.3% 32.19% at 43.13% 64.65%,
      rgba(30, 118, 200, 0.138) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(180deg, #fefefe 0%, #dceff1 100%);
}
.billing-reports__filter--mobile {
  width: 80%;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  padding-top: 36px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 2px 0 6px rgba(0, 0, 0, 0.1);
  transform: translateX(100%);
  transition: transform 0.4s ease-out, box-shadow 0.4s ease-out;
  z-index: 10;
  &.show {
    transform: translateX(0);
  }
}
@media screen and (max-width: 980px) {
  .dropdown-menu--saved-filter-mobile {
    left: initial !important;
    right: 0;
  }
}
@media screen and (max-width: 640px) {
  .dropdown-menu--saved-filter-mobile {
    min-width: 290px !important;
  }
}
.btn-toggle-input:checked + label {
  @apply text-white bg-deep-blue transition-all;
  > span {
    color: #27de39;
  }
}
@screen lg {
  .line-height-initial {
    line-height: initial;
  }
}
