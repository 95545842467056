.modal {
  @apply fixed w-full h-full flex items-center justify-center top-0 left-0 right-0 bottom-0 opacity-0 invisible;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.3s;
  z-index: 20;
  &:after {
    content: '';
    @apply fixed w-full h-full top-0 left-0 bottom-0 right-0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.3s;
    z-index: 10;
  }
  &__container {
    @apply relative w-full h-full rounded-none bg-white z-30 overflow-y-auto opacity-0 invisible;
    transform: scale(2);
    opacity: 0;
    transition: all 0.3s;
    @screen md {
      width: 506px;
      height: initial;
      border-radius: 10px;
      max-height: calc(100vh - 40px);
    }
  }
  &.show {
    @apply opacity-100 visible;
    .modal__container {
      transform: scale(1);
      opacity: 1;
      visibility: visible;
    }
  }
  &__header {
    @apply flex items-center justify-between;
  }
  &__heading {
    font-size: 22px;
    @apply font-bold;
  }
  &__sub-heading {
    font-size: 13px;
  }
  &__close-icon {
    @apply cursor-pointer;
  }
  &--no-transform {
    .modal__container {
      transform: initial !important;
    }
  }
  &--slide-right {
    justify-content: flex-end;
    .modal {
      &__header {
        background-color: #fff;
      }
      &__container {
        width: 100%;
        height: 100vh;
        max-height: 100vh;
        border-radius: 0;
        background-color: #f1f4f3;
        @screen md {
          width: 540px;
        }
      }
    }
  }
  &.open {
    opacity: 1;
    visibility: visible;
    .modal__container {
      opacity: 1;
      visibility: visible;
    }
  }
  &--subscribe-now {
    z-index: 22;
    .modal__container {
      width: 100%;
      @screen md {
        width: 50.875rem;
      }
    }
  }
  @media screen and (min-width: 980px) {
    &--edit-configuration {
      .modal__container {
        width: 75%;
      }
    }
  }
  @media screen and (min-width: 1300px) {
    &--edit-configuration {
      .modal__container {
        width: 65%;
      }
    }
  }

  @media screen and (min-width: 1080px) {
    &--confirm-delete {
      .modal__container {
        width: 540px;
      }
    }
  }
  &--run-new-test {
    .modal__container {
      width: 650px;
    }
  }
  &--upgrade-plan {
    .modal__container {
      width: 710px;
    }
  }
  &--payment-error {
    .modal__container {
      width: 627px;
    }
  }
  &--confirm-new {
    .modal__container {
      width: 826px;
    }
  }
}
